import {
    CONNECT_KEPLR_ACCOUNT_ERROR,
    CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    CONNECT_KEPLR_ACCOUNT_SUCCESS,
    CONNECT_LEAP_ACCOUNT_ERROR,
    CONNECT_LEAP_ACCOUNT_IN_PROGRESS,
    CONNECT_LEAP_ACCOUNT_SUCCESS,
    TX_HASH_FETCH_ERROR,
    TX_HASH_FETCH_IN_PROGRESS,
    TX_HASH_FETCH_SUCCESS,
    TX_HASH_IN_PROGRESS_FALSE_SET,
    TX_SIGN_AND_BROAD_CAST_ERROR,
    TX_SIGN_AND_BROAD_CAST_IN_PROGRESS,
    TX_SIGN_AND_BROAD_CAST_SUCCESS,
    TX_SIGN_ERROR,
    TX_SIGN_IN_PROGRESS,
    TX_SIGN_SUCCESS,
} from '../../constants/wallet';
import { chainConfig, chainId, config, EXPLORER_URL, walletExtensions } from '../../config';
import { AminoTypes, defaultRegistryTypes, SigningStargateClient } from '@cosmjs/stargate';
import { encodePubkey, makeSignDoc, Registry } from '@cosmjs/proto-signing';
import { encodeSecp256k1Pubkey, makeSignDoc as AminoMakeSignDoc } from '@cosmjs/amino';
import { AuthInfo, TxBody, TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import Axios from 'axios';
import { KEPLR_ACCOUNT_KEYS_SET } from '../../constants/account';
import { customRegistry, customTypes } from '../../registry';
import { fromBase64, toBase64 } from '@cosmjs/encoding';
import { convertToCamelCase } from '../../utils/strings';
import { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx';
import { MsgSend } from 'cosmjs-types/cosmos/bank/v1beta1/tx';
import { urlFetchIBCAccount, urlFetchSimulate } from '../../constants/url';
import { getOfflineSigner } from '@cosmostation/cosmos-client';
import { CosmjsOfflineSigner } from '@leapwallet/cosmos-snap-provider';
import { customAminoTypes } from '../../registry/aminoConverter';

const connectKeplrAccountInProgress = () => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    };
};

const connectKeplrAccountSuccess = (value) => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_SUCCESS,
        value,
    };
};

const connectKeplrAccountError = (message) => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const setKeplrAccountKeys = (value) => {
    return {
        type: KEPLR_ACCOUNT_KEYS_SET,
        value,
    };
};

export const initializeChain = (cb) => (dispatch) => {
    dispatch(connectKeplrAccountInProgress());
    (async () => {
        if (!window.getOfflineSigner || !window.keplr) {
            const error = 'Please install keplr extension';
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                window.open('keplrwallet://wcV1');
            } else if (/Android/i.test(navigator.userAgent)) {
                window.open('intent://wcV1#Intent;package=com.chainapsis.keplr;scheme=keplrwallet;end;');
            } else {
                window.open(walletExtensions.KEPLR);
            }
            dispatch(connectKeplrAccountError(error));
        } else {
            if (window.keplr.experimentalSuggestChain) {
                try {
                    await window.keplr.experimentalSuggestChain(chainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectKeplrAccountError(chainError));
                }
            } else {
                const versionError = 'Please use the recent version of keplr extension';
                dispatch(connectKeplrAccountError(versionError));
            }
        }

        if (window.keplr) {
            window.keplr.enable(chainId)
                .then(async () => {
                    const offlineSigner = window.getOfflineSigner(chainId);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectKeplrAccountSuccess(accounts));
                    cb(accounts);
                }).catch((error) => {
                    dispatch(connectKeplrAccountError(error.toString()));
                });
            window.keplr && window.keplr.getKey(chainId)
                .then((res) => {
                    dispatch(setKeplrAccountKeys(res));
                }).catch(() => {

                });
        } else {
            return null;
        }
    })();
};

const signTxInProgress = () => {
    return {
        type: TX_SIGN_IN_PROGRESS,
    };
};

// const setSignTxInProgress = (value) => {
//     return {
//         type: TX_SIGN_IN_PROGRESS_SET,
//         value,
//     };
// };

const signTxSuccess = (value) => {
    return {
        type: TX_SIGN_SUCCESS,
        value,
    };
};

const signTxError = (message) => {
    return {
        type: TX_SIGN_ERROR,
        message,
        variant: 'error',
    };
};

export const aminoSignTx = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        let offlineSigner;
        if (localStorage.getItem('of_market_leap')) {
            offlineSigner = await window.leap.getOfflineSigner(config.CHAIN_ID);
        } else if (localStorage.getItem('of_market_metamask')) {
            await window.ethereum && window.ethereum.enable(config.CHAIN_ID);
            offlineSigner = new CosmjsOfflineSigner(config.CHAIN_ID);
        } else if (localStorage.getItem('of_market_cosmostation')) {
            offlineSigner = await getOfflineSigner(chainId);
        } else {
            await window.keplr && window.keplr.enable(config.CHAIN_ID);
            offlineSigner = window.getOfflineSigner && window.getOfflineSigner(config.CHAIN_ID);
        }

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
            );

            const account = {};
            try {
                const {
                    accountNumber,
                    sequence,
                } = await client.getSequence(address);
                account.accountNumber = accountNumber;
                account.sequence = sequence;
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }

            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                config.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            offlineSigner.signAmino(address, signDoc).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};

export const aminoSignTxProtoTX = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        let offlineSigner;
        if (localStorage.getItem('of_market_leap')) {
            offlineSigner = await window.leap.getOfflineSigner(config.CHAIN_ID);
        } else if (localStorage.getItem('of_market_metamask')) {
            await window.ethereum && window.ethereum.enable(config.CHAIN_ID);
            offlineSigner = new CosmjsOfflineSigner(config.CHAIN_ID);
        } else if (localStorage.getItem('of_market_cosmostation')) {
            offlineSigner = await getOfflineSigner(chainId);
        } else {
            await window.keplr && window.keplr.enable(config.CHAIN_ID);
            offlineSigner = window.getOfflineSignerOnlyAmino && window.getOfflineSignerOnlyAmino(config.CHAIN_ID);
        }

        const myRegistry = new Registry([...defaultRegistryTypes, ...customRegistry]);
        const aminoTypes = new AminoTypes(customAminoTypes);

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
                {
                    registry: myRegistry,
                    aminoTypes: aminoTypes,
                    preferNoSetFee: true,
                },
            );

            client.signAndBroadcast(
                address,
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                tx.memo,
            ).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    if (result && result.transactionHash) {
                        dispatch(fetchTxHashSuccess('Success', result.transactionHash));
                    }
                    cb(result);
                }
            }).catch((error) => {
                if (error && error.message === 'Invalid string. Length must be a multiple of 4') {
                    dispatch(fetchTxHashSuccess('Success'));
                    return;
                }

                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            if (e && e.message === 'Invalid string. Length must be a multiple of 4') {
                dispatch(fetchTxHashSuccess('Success'));
                return;
            }

            dispatch(signTxError(e && e.message));
        }
    })();
};

export const protoBufSigning = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        await window.keplr && window.keplr.enable(config.CHAIN_ID);
        const offlineSigner = window.getOfflineSigner && window.getOfflineSigner(config.CHAIN_ID);
        const myRegistry = new Registry([...defaultRegistryTypes, ...customRegistry]);
        if (tx && tx.fee && tx.fee.granter && window.keplr) {
            window.keplr.defaultOptions = {
                sign: {
                    disableBalanceCheck: true,
                },
            };
        } else if (window.keplr) {
            window.keplr.defaultOptions = {};
        }

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
                { registry: myRegistry },
            );

            let account = {};
            try {
                account = await client.getAccount(address);
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }
            const accounts = await offlineSigner.getAccounts();

            let pubkey = accounts && accounts.length && accounts[0] &&
                accounts[0].pubkey && encodeSecp256k1Pubkey(accounts[0].pubkey);
            pubkey = accounts && accounts.length && accounts[0] &&
                accounts[0].pubkey && pubkey && pubkey.value &&
                encodePubkey(pubkey);

            let authInfo = {
                signerInfos: [{
                    publicKey: pubkey,
                    modeInfo: {
                        single: {
                            mode: 1,
                        },
                    },
                    sequence: account && account.sequence,
                }],
                fee: { ...tx.fee },
            };
            authInfo = AuthInfo.encode(AuthInfo.fromPartial(authInfo)).finish();

            const messages = [];
            if (tx.msgs && tx.msgs.length) {
                tx.msgs.map((val) => {
                    let msgValue = val.value;
                    msgValue = msgValue && convertToCamelCase(msgValue);
                    let typeUrl = val.typeUrl;

                    if (tx.msgType) {
                        const type = customTypes[tx.msgType].type;
                        typeUrl = customTypes[tx.msgType].typeUrl;
                        msgValue = type.encode(type.fromPartial(msgValue)).finish();
                    } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
                        msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
                    } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                        typeUrl === 'cosmos-sdk/MsgSend') {
                        typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                        msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
                    }

                    messages.push({
                        typeUrl: typeUrl,
                        value: msgValue,
                    });

                    return null;
                });
            } else {
                let msgValue = tx.msg && tx.msg.value;
                msgValue = msgValue && convertToCamelCase(msgValue);
                let typeUrl = tx.msg && tx.msg.typeUrl;

                if (tx.msgType) {
                    const type = customTypes[tx.msgType].type;
                    typeUrl = customTypes[tx.msgType].typeUrl;
                    msgValue = type.encode(type.fromPartial(msgValue)).finish();
                } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
                    msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
                } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                    typeUrl === 'cosmos-sdk/MsgSend') {
                    typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                    msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
                }

                messages.push({
                    typeUrl: typeUrl,
                    value: msgValue,
                });
            }

            let bodyBytes = {
                messages: messages,
                memo: tx.memo,
            };
            bodyBytes = TxBody.encode(TxBody.fromPartial(bodyBytes)).finish();

            const signDoc = makeSignDoc(
                bodyBytes,
                authInfo,
                config.CHAIN_ID,
                account && account.accountNumber,
            );

            offlineSigner.signDirect(address, signDoc).then((result) => {
                const txRaw = TxRaw.fromPartial({
                    bodyBytes: result.signed.bodyBytes,
                    authInfoBytes: result.signed.authInfoBytes,
                    signatures: [fromBase64(result.signature.signature)],
                });
                const txBytes = TxRaw.encode(txRaw).finish();
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result, toBase64(txBytes));
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};

export const aminoSignTxCosmoStation = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        const offlineSigner = await getOfflineSigner(chainId);

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
            );

            const account = {};
            try {
                const {
                    accountNumber,
                    sequence,
                } = await client.getSequence(address);
                account.accountNumber = accountNumber;
                account.sequence = sequence;
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }

            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                config.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            offlineSigner.signAmino(address, signDoc).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};

export const cosmoStationSigning = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        const offlineSigner = await getOfflineSigner(chainId);
        const myRegistry = new Registry([...defaultRegistryTypes, ...customRegistry]);
        if (tx && tx.fee && tx.fee.granter && window.keplr) {
            window.keplr.defaultOptions = {
                sign: {
                    disableBalanceCheck: true,
                },
            };
        } else if (window.keplr) {
            window.keplr.defaultOptions = {};
        }

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
                { registry: myRegistry },
            );

            let account = {};
            try {
                account = await client.getAccount(address);
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }
            const accounts = await offlineSigner.getAccounts();

            let pubkey = accounts && accounts.length && accounts[0] &&
                accounts[0].pubkey && encodeSecp256k1Pubkey(accounts[0].pubkey);
            pubkey = accounts && accounts.length && accounts[0] &&
                accounts[0].pubkey && pubkey && pubkey.value &&
                encodePubkey(pubkey);

            let authInfo = {
                signerInfos: [{
                    publicKey: pubkey,
                    modeInfo: {
                        single: {
                            mode: 1,
                        },
                    },
                    sequence: account && account.sequence,
                }],
                fee: { ...tx.fee },
            };
            authInfo = AuthInfo.encode(AuthInfo.fromPartial(authInfo)).finish();

            const messages = [];
            if (tx.msgs && tx.msgs.length) {
                tx.msgs.map((val) => {
                    let msgValue = val.value;
                    msgValue = msgValue && convertToCamelCase(msgValue);
                    let typeUrl = val.typeUrl;

                    if (tx.msgType) {
                        const type = customTypes[tx.msgType].type;
                        typeUrl = customTypes[tx.msgType].typeUrl;
                        msgValue = type.encode(type.fromPartial(msgValue)).finish();
                    } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
                        msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
                    } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                        typeUrl === 'cosmos-sdk/MsgSend') {
                        typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                        msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
                    }

                    messages.push({
                        typeUrl: typeUrl,
                        value: msgValue,
                    });

                    return null;
                });
            } else {
                let msgValue = tx.msg && tx.msg.value;
                msgValue = msgValue && convertToCamelCase(msgValue);
                let typeUrl = tx.msg && tx.msg.typeUrl;

                if (tx.msgType) {
                    const type = customTypes[tx.msgType].type;
                    typeUrl = customTypes[tx.msgType].typeUrl;
                    msgValue = type.encode(type.fromPartial(msgValue)).finish();
                } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
                    msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
                } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                    typeUrl === 'cosmos-sdk/MsgSend') {
                    typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                    msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
                }

                messages.push({
                    typeUrl: typeUrl,
                    value: msgValue,
                });
            }

            let bodyBytes = {
                messages: messages,
                memo: tx.memo,
            };
            bodyBytes = TxBody.encode(TxBody.fromPartial(bodyBytes)).finish();

            const signDoc = makeSignDoc(
                bodyBytes,
                authInfo,
                config.CHAIN_ID,
                account && account.accountNumber,
            );

            offlineSigner.signDirect(address, signDoc).then((result) => {
                const txRaw = TxRaw.fromPartial({
                    bodyBytes: result.signed.bodyBytes,
                    authInfoBytes: result.signed.authInfoBytes,
                    signatures: [fromBase64(result.signature.signature)],
                });
                const txBytes = TxRaw.encode(txRaw).finish();
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result, toBase64(txBytes));
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};

const txSignAndBroadCastInProgress = () => {
    return {
        type: TX_SIGN_AND_BROAD_CAST_IN_PROGRESS,
    };
};

const txSignAndBroadCastSuccess = (value, message, variant, hash, explorer) => {
    return {
        type: TX_SIGN_AND_BROAD_CAST_SUCCESS,
        value,
        message,
        variant,
        hash,
        explorer,
    };
};

const txSignAndBroadCastError = (message) => {
    return {
        type: TX_SIGN_AND_BROAD_CAST_ERROR,
        message,
        variant: 'error',
    };
};

export const txSignAndBroadCast = (data, cb) => (dispatch) => {
    dispatch(txSignAndBroadCastInProgress());

    const url = config.REST_URL + '/cosmos/tx/v1beta1/txs';
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            if (res.data && res.data.tx_response && (res.data.tx_response.code !== undefined) && (res.data.tx_response.code !== 0)) {
                dispatch(txSignAndBroadCastError(res.data.tx_response.logs && res.data.tx_response.logs.length
                    ? res.data.tx_response.logs
                    : res.data.tx_response.raw_log));
                cb(null);
            } else {
                const message = 'Transaction is in progress';
                dispatch(txSignAndBroadCastSuccess(res.data && res.data.tx_response, message, 'processing',
                    res.data && res.data.tx_response && res.data.tx_response.txhash));
                cb(res.data && res.data.tx_response);
            }
        })
        .catch((error) => {
            dispatch(txSignAndBroadCastError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

export const txSignAndBroadCastAminoSign = (data, cb) => (dispatch) => {
    dispatch(txSignAndBroadCastInProgress());

    const url = config.REST_URL + '/txs';
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            if (res.data && res.data.code !== undefined && (res.data.code !== 0)) {
                dispatch(txSignAndBroadCastError(res.data.logs || res.data.raw_log));
                cb(null);
            } else {
                const message = 'Transaction is in progress';
                dispatch(txSignAndBroadCastSuccess(res.data, message, 'processing',
                    res.data && res.data.txhash));
                cb(res.data);
            }
        })
        .catch((error) => {
            dispatch(txSignAndBroadCastError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

export const ibcTxSignAndBroadCast = (data, ibcConfig, explorer, cb) => (dispatch) => {
    dispatch(txSignAndBroadCastInProgress());

    const url = ibcConfig.REST_URL + '/cosmos/tx/v1beta1/txs';
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            if (res.data && res.data.tx_response && (res.data.tx_response.code !== undefined) && (res.data.tx_response.code !== 0)) {
                dispatch(txSignAndBroadCastError(res.data.tx_response.logs && res.data.tx_response.logs.length
                    ? res.data.tx_response.logs
                    : res.data.tx_response.raw_log));
                cb(null);
            } else {
                const message = 'Transaction is in progress';
                dispatch(txSignAndBroadCastSuccess(res.data && res.data.tx_response, message, 'processing',
                    res.data && res.data.tx_response && res.data.tx_response.txhash, explorer || EXPLORER_URL));
                cb(res.data && res.data.tx_response);
            }
        })
        .catch((error) => {
            dispatch(txSignAndBroadCastError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchTxHashInProgress = () => {
    return {
        type: TX_HASH_FETCH_IN_PROGRESS,
    };
};

const fetchTxHashSuccess = (message, hash) => {
    return {
        type: TX_HASH_FETCH_SUCCESS,
        message,
        variant: 'success',
        hash,
    };
};

const fetchTxHashError = () => {
    return {
        type: TX_HASH_FETCH_ERROR,
    };
};

export const fetchTxHash = (hash, cb) => (dispatch) => {
    dispatch(fetchTxHashInProgress());

    const url = config.REST_URL + '/cosmos/tx/v1beta1/txs/' + hash;
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            if (res.data && res.data.tx_response && res.data.tx_response.code !== undefined && res.data.tx_response.code !== 0) {
                dispatch(fetchTxHashError(res.data.tx_response.raw_log || res.data.tx_response.logs));
                cb(res.data.tx_response);
            } else {
                dispatch(fetchTxHashSuccess('Transaction successful', hash));
                cb(res.data);
            }
        })
        .catch((error) => {
            dispatch(fetchTxHashError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : error.response &&
                    error.response.data &&
                    error.response.data.error
                        ? error.response.data.error
                        : 'Failed!',
            ));
            cb(null);
        });
};

export const setTxHashInProgressFalse = () => {
    return {
        type: TX_HASH_IN_PROGRESS_FALSE_SET,
    };
};

export const gasEstimation = (tx, pubKey, address, IBCConfig, protoType, cb) => (dispatch) => {
    dispatch(signTxInProgress());

    const url = urlFetchIBCAccount((IBCConfig && IBCConfig.REST_URL) || config.REST_URL, address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((response) => {
        const sequence = response && response.data && response.data.account && response.data.account.sequence;
        dispatch(handleGasEstimation(sequence, tx, pubKey, address, IBCConfig, protoType, cb));
    }).catch(() => {
        const sequence = 0;
        dispatch(handleGasEstimation(sequence, tx, pubKey, address, IBCConfig, protoType, cb));
    });
};

const handleGasEstimation = (sequence, tx, pubKey, address, IBCConfig, protoType, cb) => (dispatch) => {
    let pubkey = pubKey;
    pubkey = pubkey && pubkey.value && encodePubkey(pubkey);

    let authInfo = {
        signerInfos: [{
            publicKey: pubkey,
            modeInfo: {
                single: {
                    mode: 0,
                },
            },
            sequence: sequence,
        }],
        fee: {
            amount: tx.fee && tx.fee.amount,
            gasLimit: tx.fee && tx.fee.gas,
        },
    };
    authInfo = AuthInfo.encode(AuthInfo.fromPartial(authInfo)).finish();

    const messages = [];
    if (tx.msgs && tx.msgs.length) {
        tx.msgs.map((val) => {
            let msgValue = val.value;
            msgValue = msgValue && convertToCamelCase(msgValue);
            let typeUrl = val.typeUrl;

            if (protoType) {
                const type = customTypes[tx.msgType].type;
                typeUrl = customTypes[tx.msgType].typeUrl;
                msgValue = type.encode(type.fromPartial(msgValue)).finish();
            } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer' ||
                typeUrl === 'cosmos-sdk/MsgTransfer') {
                typeUrl = '/ibc.applications.transfer.v1.MsgTransfer';
                msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
            } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                typeUrl === 'cosmos-sdk/MsgSend') {
                typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
            }

            messages.push({
                typeUrl: typeUrl,
                value: msgValue,
            });

            return null;
        });
    } else {
        let msgValue = tx.msg && tx.msg.value;
        msgValue = msgValue && convertToCamelCase(msgValue);
        let typeUrl = tx.msg && tx.msg.typeUrl;

        if (protoType) {
            const type = customTypes[tx.msgType].type;
            typeUrl = customTypes[tx.msgType].typeUrl;
            msgValue = type.encode(type.fromPartial(msgValue)).finish();
        } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer' ||
            typeUrl === 'cosmos-sdk/MsgTransfer') {
            typeUrl = '/ibc.applications.transfer.v1.MsgTransfer';
            msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
        } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
            typeUrl === 'cosmos-sdk/MsgSend') {
            typeUrl = '/cosmos.bank.v1beta1.MsgSend';
            msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
        }

        messages.push({
            typeUrl: typeUrl,
            value: msgValue,
        });
    }

    let bodyBytes = {
        messages: messages,
        memo: tx.memo,
    };
    bodyBytes = TxBody.encode(TxBody.fromPartial(bodyBytes)).finish();

    const txRaw = TxRaw.fromPartial({
        bodyBytes: bodyBytes,
        authInfoBytes: authInfo,
        signatures: [new Uint8Array()],
    });

    const txBytes = TxRaw.encode(txRaw).finish();

    const simulateUrl = urlFetchSimulate((IBCConfig && IBCConfig.REST_URL) || config.REST_URL);
    Axios.post(simulateUrl, {
        tx_bytes: toBase64(txBytes),
    }, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((response) => {
            cb(response && response.data && response.data.gas_info);
        }).catch(() => {
            cb(null);
        });
};

const connectLeapAccountInProgress = () => {
    return {
        type: CONNECT_LEAP_ACCOUNT_IN_PROGRESS,
    };
};

const connectLeapAccountSuccess = (value) => {
    return {
        type: CONNECT_LEAP_ACCOUNT_SUCCESS,
        value,
    };
};

const connectLeapAccountError = (message) => {
    return {
        type: CONNECT_LEAP_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const initializeLeap = (cb) => (dispatch) => {
    dispatch(connectLeapAccountInProgress());
    (async () => {
        if (!window.leap || (window.leap && !window.leap.getOfflineSigner)) {
            const error = 'Please install leap extension';
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                window.open('https://leapcosmoswallet.page.link/TEs32mzKdBiWTv9j8');
            } else {
                window.open(walletExtensions.LEAP);
            }
            dispatch(connectLeapAccountError(error));
        } else {
            if (window.leap.experimentalSuggestChain) {
                try {
                    await window.leap.experimentalSuggestChain(chainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectLeapAccountError(chainError));
                }
            } else {
                const versionError = 'Please use the recent version of Leap extension';
                dispatch(connectLeapAccountError(versionError));
            }
        }

        if (window.leap) {
            window.leap.enable(chainId)
                .then(async () => {
                    const offlineSigner = window.leap.getOfflineSigner(chainId);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectLeapAccountSuccess(accounts));
                    cb(accounts);

                    window.leap.getKey(chainId).then((res) => {
                        dispatch(setKeplrAccountKeys(res));
                    }).catch(() => {

                    });
                }).catch((error) => {
                    dispatch(connectLeapAccountError(error.toString()));
                });
        } else {
            return null;
        }
    })();
};

export const leapSigning = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        const offlineSigner = await window.leap.getOfflineSigner(chainId);
        const myRegistry = new Registry([...defaultRegistryTypes, ...customRegistry]);
        if (tx && tx.fee && tx.fee.granter && window.leap) {
            window.leap.defaultOptions = {
                sign: {
                    disableBalanceCheck: true,
                },
            };
        } else if (window.leap) {
            window.leap.defaultOptions = {};
        }

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
                { registry: myRegistry },
            );

            let account = {};
            try {
                account = await client.getAccount(address);
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }
            const accounts = await offlineSigner.getAccounts();

            let pubkey = accounts && accounts.length && accounts[0] &&
                accounts[0].pubkey && encodeSecp256k1Pubkey(accounts[0].pubkey);
            pubkey = accounts && accounts.length && accounts[0] &&
                accounts[0].pubkey && pubkey && pubkey.value &&
                encodePubkey(pubkey);

            let authInfo = {
                signerInfos: [{
                    publicKey: pubkey,
                    modeInfo: {
                        single: {
                            mode: 1,
                        },
                    },
                    sequence: account && account.sequence,
                }],
                fee: { ...tx.fee },
            };
            authInfo = AuthInfo.encode(AuthInfo.fromPartial(authInfo)).finish();

            const messages = [];
            if (tx.msgs && tx.msgs.length) {
                tx.msgs.map((val) => {
                    let msgValue = val.value;
                    msgValue = msgValue && convertToCamelCase(msgValue);
                    let typeUrl = val.typeUrl;

                    if (tx.msgType) {
                        const type = customTypes[tx.msgType].type;
                        typeUrl = customTypes[tx.msgType].typeUrl;
                        msgValue = type.encode(type.fromPartial(msgValue)).finish();
                    } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
                        msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
                    } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                        typeUrl === 'cosmos-sdk/MsgSend') {
                        typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                        msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
                    }

                    messages.push({
                        typeUrl: typeUrl,
                        value: msgValue,
                    });

                    return null;
                });
            } else {
                let msgValue = tx.msg && tx.msg.value;
                msgValue = msgValue && convertToCamelCase(msgValue);
                let typeUrl = tx.msg && tx.msg.typeUrl;

                if (tx.msgType) {
                    const type = customTypes[tx.msgType].type;
                    typeUrl = customTypes[tx.msgType].typeUrl;
                    msgValue = type.encode(type.fromPartial(msgValue)).finish();
                } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
                    msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
                } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                    typeUrl === 'cosmos-sdk/MsgSend') {
                    typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                    msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
                }

                messages.push({
                    typeUrl: typeUrl,
                    value: msgValue,
                });
            }

            let bodyBytes = {
                messages: messages,
                memo: tx.memo,
            };
            bodyBytes = TxBody.encode(TxBody.fromPartial(bodyBytes)).finish();

            const signDoc = makeSignDoc(
                bodyBytes,
                authInfo,
                config.CHAIN_ID,
                account && account.accountNumber,
            );

            offlineSigner.signDirect(address, signDoc).then((result) => {
                const txRaw = TxRaw.fromPartial({
                    bodyBytes: result.signed.bodyBytes,
                    authInfoBytes: result.signed.authInfoBytes,
                    signatures: [fromBase64(result.signature.signature)],
                });
                const txBytes = TxRaw.encode(txRaw).finish();
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result, toBase64(txBytes));
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};

export const aminoSignTxLeap = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        await window.leap && window.leap.enable(config.CHAIN_ID);
        const offlineSigner = window.leap.getOfflineSigner && window.leap.getOfflineSigner(config.CHAIN_ID);

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
            );

            const account = {};
            try {
                const {
                    accountNumber,
                    sequence,
                } = await client.getSequence(address);
                account.accountNumber = accountNumber;
                account.sequence = sequence;
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }

            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                config.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            offlineSigner.signAmino(address, signDoc).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};

export const metaMaskSigning = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        await window.ethereum && window.ethereum.enable(config.CHAIN_ID);
        const offlineSigner = new CosmjsOfflineSigner(chainId);
        const myRegistry = new Registry([...defaultRegistryTypes, ...customRegistry]);
        if (tx && tx.fee && tx.fee.granter && window.leap) {
            window.leap.defaultOptions = {
                sign: {
                    disableBalanceCheck: true,
                },
            };
        } else if (window.leap) {
            window.leap.defaultOptions = {};
        }

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
                { registry: myRegistry },
            );

            let account = {};
            try {
                account = await client.getAccount(address);
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }
            const accounts = await offlineSigner.getAccounts();

            let pubkey = accounts && accounts.length && accounts[0] &&
                accounts[0].pubkey && encodeSecp256k1Pubkey(accounts[0].pubkey);
            pubkey = accounts && accounts.length && accounts[0] &&
                accounts[0].pubkey && pubkey && pubkey.value &&
                encodePubkey(pubkey);

            let authInfo = {
                signerInfos: [{
                    publicKey: pubkey,
                    modeInfo: {
                        single: {
                            mode: 1,
                        },
                    },
                    sequence: account && account.sequence,
                }],
                fee: { ...tx.fee },
            };
            authInfo = AuthInfo.encode(AuthInfo.fromPartial(authInfo)).finish();

            const messages = [];
            if (tx.msgs && tx.msgs.length) {
                tx.msgs.map((val) => {
                    let msgValue = val.value;
                    msgValue = msgValue && convertToCamelCase(msgValue);
                    let typeUrl = val.typeUrl;

                    if (tx.msgType) {
                        const type = customTypes[tx.msgType].type;
                        typeUrl = customTypes[tx.msgType].typeUrl;
                        msgValue = type.encode(type.fromPartial(msgValue)).finish();
                    } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
                        msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
                    } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                        typeUrl === 'cosmos-sdk/MsgSend') {
                        typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                        msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
                    }

                    messages.push({
                        typeUrl: typeUrl,
                        value: msgValue,
                    });

                    return null;
                });
            } else {
                let msgValue = tx.msg && tx.msg.value;
                msgValue = msgValue && convertToCamelCase(msgValue);
                let typeUrl = tx.msg && tx.msg.typeUrl;

                if (tx.msgType) {
                    const type = customTypes[tx.msgType].type;
                    typeUrl = customTypes[tx.msgType].typeUrl;
                    msgValue = type.encode(type.fromPartial(msgValue)).finish();
                } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
                    msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
                } else if (typeUrl === '/cosmos.bank.v1beta1.MsgSend' ||
                    typeUrl === 'cosmos-sdk/MsgSend') {
                    typeUrl = '/cosmos.bank.v1beta1.MsgSend';
                    msgValue = MsgSend.encode(MsgSend.fromPartial(msgValue)).finish();
                }

                messages.push({
                    typeUrl: typeUrl,
                    value: msgValue,
                });
            }

            let bodyBytes = {
                messages: messages,
                memo: tx.memo,
            };
            bodyBytes = TxBody.encode(TxBody.fromPartial(bodyBytes)).finish();

            const signDoc = makeSignDoc(
                bodyBytes,
                authInfo,
                config.CHAIN_ID,
                account && account.accountNumber,
            );

            offlineSigner.signDirect(address, signDoc).then((result) => {
                const txRaw = TxRaw.fromPartial({
                    bodyBytes: result.signed.bodyBytes,
                    authInfoBytes: result.signed.authInfoBytes,
                    signatures: [fromBase64(result.signature.signature)],
                });
                const txBytes = TxRaw.encode(txRaw).finish();
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result, toBase64(txBytes));
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};

export const aminoSignTxMetaMask = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        await window.ethereum && window.ethereum.enable(config.CHAIN_ID);
        const offlineSigner = new CosmjsOfflineSigner(config.CHAIN_ID);

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
            );

            const account = {};
            try {
                const {
                    accountNumber,
                    sequence,
                } = await client.getSequence(address);
                account.accountNumber = accountNumber;
                account.sequence = sequence;
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }

            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                config.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            offlineSigner.signAmino(address, signDoc).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};
